import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import MediaQuery from 'react-responsive'
import mediaSize from '../../constants/mediaSize'

import {
  SelfEmployerAnswer,
  SelfEmployerInstruction,
  PaymentMethods,
  PrivacyPolicy,
  PaymentPolicy,
  NominalBill,
} from './Pages'
import { MobileClose } from '../../Components'

import styles from './styles.module.scss'

class Documents extends Component {
  static propTypes = {
    hash: PropTypes.string,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      hash: PropTypes.string,
    }),
    history: PropTypes.shape({
      goBack: PropTypes.func,
      push: PropTypes.func,
    }),
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto'
  }

  handleClosePage = () => {
    const {
      location: { pathname },
      history: { push },
    } = this.props
    push(pathname)
  }

  getPage = () => {
    const { hash } = this.props.location
    switch (hash) {
      case '#document-selfEmployer-answer':
        return <SelfEmployerAnswer />
      case '#document-selfEmployer-instruction':
        return <SelfEmployerInstruction />
      case '#document-payment-methods':
        return <PaymentMethods />
      case '#document-privacy-policy':
        return <PrivacyPolicy />
      case '#document-payment-policy':
        return <PaymentPolicy />
      case '#document-nominal-bill-bank131':
        return <NominalBill />
      default:
        return null
    }
  }

  render() {
    const {
      handleClosePage,
      getPage,
    } = this

    if (!getPage()) {
      handleClosePage()
    }

    return (
      <div className={styles.wrap}>
        <div className={styles.card}>
          <div className={styles.content}>
            <div className={classNames('base-layout', styles.layout)}>
              <div className={styles.contentWrap}>
                { getPage() }
              </div>
            </div>
            <MediaQuery minWidth={mediaSize.maxPhoneSize}>
              <div className={styles.closeWrap}>
                <div className="base-layout">
                  <button className={styles.close} onClick={handleClosePage}>
                    <i className="i_close" />
                  </button>
                </div>
              </div>
            </MediaQuery>
          </div>
          <MediaQuery maxWidth={mediaSize.maxPhoneSize - 1}>
            <MobileClose handlePress={handleClosePage} />
          </MediaQuery>
        </div>
      </div>
    )
  }
}

export default withRouter(Documents)
