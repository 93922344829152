import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

import blob from './img/blob.png'
import cross from './img/cross.svg'

const MobileClose = props => (
  <button
    type="button"
    onClick={props.handlePress}
    className={styles.button}
  >
    <img alt="blob" src={blob} width={105} height={137} className={styles.blob} />
    <img alt="close" src={cross} className={styles.cross} />
  </button>
)

MobileClose.propTypes = {
  handlePress: PropTypes.func,
}

MobileClose.defaultProps = {
  handlePress: () => {},
}

export default MobileClose
