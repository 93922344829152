import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import MediaQuery from 'react-responsive'
import mediaSize from '../../../constants/mediaSize'

import Parallax from '../../../constants/parallax'

import styles from './styles.module.scss'

class Conditions extends React.PureComponent {
  static propTypes = {
    activeTab: PropTypes.oneOf(['Фрилансерам', 'Организациям']).isRequired,
  }

  constructor(props) {
    super(props)

    this.parallaxComponents = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ]
  }

  componentDidMount() {
    const { init, cleanup } = new Parallax(this.parallaxComponents)
    init()
    this.cleanupParallax = cleanup
  }

  componentWillUnmount() {
    this.cleanupParallax()
  }

  render() {
    const { activeTab } = this.props
    const isFreelance = activeTab === 'Фрилансерам'

    return (
      <div className={styles.wrap}>
        <MediaQuery minWidth={mediaSize.maxPhoneSize}>
          <div className={styles.logoBobbleWrap}>
            <div className="base-layout">
              <img
                alt="blobTop"
                src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/innerPages/blob_top.png`}
                className={styles.blobTop}
                width={190}
              />
              <img
                ref={this.parallaxComponents[0]}
                alt="pinBig"
                data-parallax="16"
                src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/innerPages/logo_big.svg`}
                className={styles.logoBobble}
              />
            </div>
          </div>
          <img
            alt="waveTop"
            src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/innerPages/wave_top.webp`}
            className={styles.waveTop}
          />
          <div className={styles.bobblesWrap}>
            <div className="base-layout">
              <div className={styles.bobbles}>
                <div
                  ref={this.parallaxComponents[1]}
                  data-parallax="8"
                  className={classNames('parallax', styles.bobble, styles.bobbleYandex)}
                >
                  <i className="i_yoomoney_h" />
                </div>
                <div
                  ref={this.parallaxComponents[2]}
                  data-parallax="4"
                  className={classNames(styles.bobble, styles.bobbleWM)}
                >
                  <i className="i_sbp_h" />
                </div>
                <div
                  ref={this.parallaxComponents[3]}
                  data-parallax="4"
                  className={classNames(styles.bobble, styles.bobbleVisa)}
                >
                  <i className="i_visa_h" />
                </div>
                <div
                  ref={this.parallaxComponents[4]}
                  data-parallax="8"
                  className={classNames(styles.bobble, styles.bobbleMir)}
                >
                  <i className="i_mir_h" />
                </div>
                <div
                  ref={this.parallaxComponents[5]}
                  data-parallax="8"
                  className={classNames(styles.bobble, styles.bobbleMactercard)}
                >
                  <i className="i_mastercard_h" />
                </div>
                <div
                  ref={this.parallaxComponents[6]}
                  data-parallax="4"
                  className={classNames(styles.bobble, styles.bobbleQiwi)}
                >
                  <i className="i_qiwi_h" />
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <div className={styles.contentWrap}>
          <div className="base-layout">
            <div className={styles.content}>
              <div className={styles.block}>
                <div className={styles.blockHeader}>
                  <div className={styles.blockHeaderContent}>
                    <h3 className={styles.blockTitle}>
                      { isFreelance ? 'При оплате' : 'Приём платежей' }
                    </h3>
                    <p className={styles.blockText}>
                      { 'Ваши клиенты оплачивают сделку напрямую. Расчёты только через кредитную организацию.' }
                    </p>
                  </div>
                  <div className={styles.blockCircleWrap}>
                    <img
                      alt="lineRefill"
                      /* eslint-disable-next-line max-len */
                      src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/tariffs/conditions/line_refill.svg`}
                      className={styles.blockCircleLineRefill}
                    />
                    <div className={classNames(styles.blockCircle, styles.blockCircleRefill)}>
                      <div className={styles.blockCircleContent}>
                        <span className={styles.blockCircleText}>{isFreelance ? 0 : '~2'}</span>
                        <span>%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.blockFooter}>
                  <ul className={styles.blockList}>
                    <li className={styles.blockListItem}>
                      <i className={classNames(styles.blockListItemPin, styles.blockListItemPinGreen)} />
                      { 'Удобный личный кабинет' }
                    </li>
                    <li className={styles.blockListItem}>
                      <i className={classNames(styles.blockListItemPin, styles.blockListItemPinGreen)} />
                      { isFreelance ? 'Фискализация дохода' : 'Гибкие условия подключения' }
                    </li>
                    <li className={styles.blockListItem}>
                      <i className={classNames(styles.blockListItemPin, styles.blockListItemPinGreen)} />
                      { 'Персональная тех. поддержка' }
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles.block}>
                <div className={styles.blockHeader}>
                  <div className={styles.blockHeaderContent}>
                    <h3 className={styles.blockTitle}>
                      { isFreelance ? 'При выплате' : 'Массовые выплаты' }
                    </h3>
                    <p className={styles.blockText}>
                      { 'Деньги выплачиваются сразу после выполнения условий сделки. Расчёты только через банк.' }
                    </p>
                  </div>
                  <div className={styles.blockCircleWrap}>
                    <img
                      alt="linePayments"
                      /* eslint-disable-next-line max-len */
                      src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/tariffs/conditions/line_paments.svg`}
                      className={styles.blockCircleLinePayments}
                    />
                    <div className={classNames(styles.blockCircle, styles.blockCirclePayments)}>
                      <div className={styles.blockCircleContent}>
                        <span className={styles.blockCircleText}>{isFreelance ? '~5' : '~2'}</span>
                        <span>%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.blockFooter}>
                  <ul className={styles.blockList}>
                    <li className={styles.blockListItem}>
                      <i className={classNames(styles.blockListItemPin, styles.blockListItemPinOrange)} />
                      { 'Деньги поступят моментально' }
                    </li>
                    <li className={styles.blockListItem}>
                      <i className={classNames(styles.blockListItemPin, styles.blockListItemPinOrange)} />
                      { isFreelance ? 'Доход от физического лица' : 'Фискализация дохода' }
                    </li>
                    <li className={styles.blockListItem}>
                      <i className={classNames(styles.blockListItemPin, styles.blockListItemPinOrange)} />
                      { 'Низкая комиссия на все переводы' }
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MediaQuery minWidth={mediaSize.maxPhoneSize}>
          <img
            alt="waveBottom"
            src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/innerPages/wave_bottom.webp`}
            className={styles.waveBottom}
          />
        </MediaQuery>
      </div>
    )
  }
}

export default Conditions
