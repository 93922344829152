import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'

import { ScrollToTop } from './Components'

import './index.scss'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <BrowserRouter>
    <>
      <ScrollToTop />
      <Routes />
    </>
  </BrowserRouter>,
  document.getElementById('root')
)

serviceWorker.unregister()
