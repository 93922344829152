import React from 'react'
import MediaQuery from 'react-responsive'
import mediaSize from '../../../../constants/mediaSize'

import styles from './styles.module.scss'

export default () => (
  <div className={styles.wrap}>
    <div className="base-layout">
      <div className={styles.content}>
        <div className={styles.column}>
          <MediaQuery minWidth={mediaSize.maxPhoneSize}>
            <img
              alt="dashes"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/home/advantages/dashes.svg`}
              className={styles.dashes}
              width={288}
              height={170}
            />
          </MediaQuery>
          <div className={styles.card}>
            <h3 className={styles.cardTitle}>
              { 'Без договора' }
            </h3>
            <p className={styles.cardText}>
              { 'Подключитесь, '}
              <a className={styles.cardLink} href="#document-selfEmployer-instruction">{'получите статус'}</a>
              {' самозанятого в приложении «Мой налог» или на сайте ФНС, следуйте инструкциям. '
              + 'Вам только потребуется привязать карту и\u00A0создать первый счёт.'}
            </p>
          </div>
          <div className={styles.creditCardWrap}>
            <i className={styles.creditCardBack} />
            <img
              alt="card"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/home/advantages/card.webp`}
              className={styles.creditCard}
            />
            <img
              alt="cardFront"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/home/advantages/card_front.svg`}
              className={styles.creditCardFront}
            />
          </div>
          <div className={styles.card}>
            <h3 className={styles.cardTitle}>
              { 'Быстро снять' }
            </h3>
            <p className={styles.cardText}>
              { 'Привяжите любую банковскую карту, укажите сумму перевода и\u00A0нажмите отправить. '
              + 'Можно сохранить реквизиты и\u00A0настроить собственный график выплат.' }
            </p>
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.card}>
            <img
              alt="tie"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/home/advantages/tie.svg`}
              className={styles.tie}
            />
            <h3 className={styles.cardTitle}>
              { 'Работа с ФНС' }
            </h3>
            <p className={styles.cardText}>
              { 'Регистрируйте полученный доход, формируйте чеки для платежей '
              + 'и\u00A0отмененных операций. Получайте уведомления, рекомендации '
              + 'и\u00A0помощь личного менеджера при работе с\u00A0ФНС.' }
            </p>
          </div>
          <div className={styles.card}>
            <h3 className={styles.cardTitle}>
              { 'Цифровая карта' }
            </h3>
            <p className={styles.cardText}>
              { 'Получите новую цифровую карту. Привяжите карту к вашему телефону и\u00A0совершайте '
              + 'покупки в любых офлайн магазинах. Баланс карты пополняется за счёт входящих платежей.' }
            </p>
          </div>
          <div className={styles.card}>
            <h3 className={styles.cardTitle}>
              { 'Удобно получить' }
            </h3>
            <p className={styles.cardText}>
              { 'Разместите платежную форму или используйте наше API. '
              + 'Можно оплатить QR-кодом, банковской картой или электронным кошельком.' }
            </p>
            <div className={styles.cardImagesWrap}>
              <i className="i_visa" />
              <MediaQuery minWidth={mediaSize.maxPhoneSize}>
                <i className="i_mir" />
              </MediaQuery>
              <i className="i_mastercard" />
              <i className="i_qiwi" />
              <i className="i_plus" />
            </div>
          </div>
          <MediaQuery minWidth={mediaSize.maxPhoneSize}>
            {/*
            <img alt="bubbles" src={bubbles} className={styles.bubbles} />
            */}
            <img
              alt="blobBig"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/home/advantages/blob_big.png`}
              className={styles.blobBig}
              width={195}
              height={194}
            />
            <img
              alt="blobSmall"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/home/advantages/blob_small.png`}
              className={styles.blobSmall}
              width={115}
              height={112}
            />
          </MediaQuery>
        </div>
      </div>
    </div>
  </div>
)
