import React from 'react'
import MediaQuery from 'react-responsive'
import mediaSize from '../../../constants/mediaSize'

import styles from './styles.module.scss'

// import link from './img/link.svg'
// import linkActive from './img/link_red.svg'

const Licenses = () => (
  <div className={styles.wrap}>
    <div className="base-layout">
      <MediaQuery minWidth={mediaSize.maxPhoneSize}>
        <img
          alt="map"
          src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/licenses/map.webp`}
          className={styles.backgroundMap}
        />
      </MediaQuery>
      <div className={styles.content}>
        <MediaQuery minWidth={mediaSize.maxPhoneSize}>
          <div className={styles.info}>
            <h2 className={styles.title}>
              { 'Безопасно и\u00A0легально' }
            </h2>
            <p className={styles.text}>
              { 'Мы - лицензированы и\u00A0строго соблюдаем международные стандарты безопасности, '
              + 'приема платежей и\u00A0обработки персональных данных.' }
            </p>
          </div>
        </MediaQuery>
        <div className={styles.blocksWrap}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://npd.nalog.ru/aggregators/"
            className={styles.block}
          >
            <img
              alt="licenceMas"
              width={49}
              height={60}
              /* eslint-disable-next-line max-len */
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/licenses/licence_mas.webp`}
            />
            <pre className={styles.blockText}>
              { 'Оператор электронных \nплощадок' }
            </pre>
            {/*
            <img alt="link" src={link} className={styles.link} />
            <img alt="linkActive" src={linkActive} className={styles.linkActive} />
            */}
          </a>
          <div className={styles.block}>
            <img
              alt="licencePCI"
              width={49}
              height={60}
              /* eslint-disable-next-line max-len */
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/licenses/license_pci.webp`}
            />
            <pre className={styles.blockText}>
              { 'Сертификат ' }
              <MediaQuery minWidth={mediaSize.maxPhoneSize}>
                { 'соответствия' }
              </MediaQuery>
              { '\nPCI DSS' }
            </pre>
            {/*
            <img alt="link" src={link} className={styles.link} />
            <img alt="linkActive" src={linkActive} className={styles.linkActive} />
            */}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Licenses
