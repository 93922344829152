import raf from 'raf'

let id = 0
const ids = {}

export default function wrapperRaf(callback = () => {}, delayFrames = 1) {
  //  eslint-disable-next-line
  const myId = id++
  let restFrames = delayFrames

  function internalCallback() {
    restFrames -= 1

    if (restFrames <= 0) {
      callback()
      delete ids[id]
    } else {
      ids[id] = raf(internalCallback)
    }
  }

  ids[id] = raf(internalCallback)

  return myId
}

wrapperRaf.cancel = (pid) => {
  raf.cancel(ids[pid])
  delete ids[pid]
}
