import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'
import mediaSize from '../../constants/mediaSize'

import styles from './styles.module.scss'

import {
  Header,
  // About,
  Advantages,
} from './components'

import {
  Connection,
  ApiInformation,
  FooterWrap,
} from '../../Components'

export default class Home extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      hash: PropTypes.string,
    }),
  }

  constructor(props) {
    super(props)
    this.state = {
      isShowTitle: !props.location.hash.length,
    }
  }

  componentDidMount() {
    setTimeout(() => NProgress.done(), 300)
  }

  componentDidUpdate() {
    this.setIsShowTitle(this.props)
  }

  componentWillUnmount() {
    NProgress.start()
  }

  setIsShowTitle = props => this.setState({ isShowTitle: !props.location.hash.length })

  render() {
    const { isShowTitle } = this.state

    return (
      <>
        {isShowTitle && (
          <Helmet>
            <title>Прямые сделки для самозанятых :. tome</title>
            <meta property="og:title" content="Прямые сделки для самозанятых" />
            <meta property="og:url" content="https://www.tome.ge/" />
          </Helmet>
        )}
        <section className={styles.headerWrap}>
          <MediaQuery minWidth={mediaSize.maxPhoneSize}>
            <i className={classNames('i_waveHeaderBG', styles.headerImage)} />
          </MediaQuery>
          <MediaQuery maxWidth={mediaSize.maxPhoneSize - 1}>
            <img
              alt="waveMobile"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/wave_header_bottom_mobile.webp`}
              className={styles.headerImageMobile}
              width={940}
              height={564}
              fetchpriority="high"
            />
          </MediaQuery>
          <Header />
          <MediaQuery minWidth={mediaSize.maxPhoneSize}>
            <div className={styles.headerWave}>
              <i className={styles.headerWaveImage} />
              {/* <WaveTop className={styles.headerWaveImage} /> */}
              <img
                alt="blob"
                src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/home/blob_small.png`}
                className={styles.headerWaveBlobSmall}
                width={100}
                height={96}
              />
              <img
                alt="blob"
                src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/home/blob_middle.png`}
                className={styles.headerWaveBlob}
                width={141}
                height={133}
              />
              <img
                alt="blob"
                src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/home/blob_big.png`}
                className={styles.headerWaveBlobBig}
                width={214}
                height={198}
              />
            </div>
          </MediaQuery>
          {/*
      <MediaQuery minWidth={mediaSize.maxPhoneSize}>
        <About />
      </MediaQuery>
      */}
        </section>
        <Advantages />
        <MediaQuery minWidth={mediaSize.maxPhoneSize}>
          <ApiInformation />
        </MediaQuery>
        <FooterWrap>
          <Connection isHome />
        </FooterWrap>
      </>
    )
  }
}
