import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.scss'

const InnerPageHeader = props => (
  <header className={classNames(styles.wrap, props.wrapStyles)}>
    <div className="base-layout">
      <div className={styles.content}>
        <h1 className={styles.title}>
          { props.title }
        </h1>
        <p className={styles.text}>
          { props.text }
        </p>
        { props.children }
      </div>
    </div>
  </header>
)

InnerPageHeader.propTypes = {
  wrapStyles: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  children: PropTypes.element,
}

export default InnerPageHeader
