import React from 'react'
import { Helmet } from 'react-helmet'

export default () => (
  <div className="document">
    <Helmet>
      <title>Способы оплаты :. tome</title>
      <meta property="og:title" content="Инструкция: подключение к электронной площадке" />
      <meta property="og:url" content="https://tome.ge/#document-payment-methods" />
      <meta property="og:type" content="article" />
    </Helmet>
    <aside>
      <span>29 октября 2020 г.</span>
    </aside>
    <h2>Способы оплаты</h2>
    <p>Сервис предоставляет широкие возможности оплаты как с электронных кошельков, так и с банковских карты международных платежных систем.</p>
    <h3>Банковской картой</h3>
    <p>Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку «Оплатить. Оплата происходит через ПАО «СБЕРБАНК» с использованием банковских карт следующих платёжных систем: МИР, Visa Int., MasterCard Europe Sprl, JCB.</p>
    <p>Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure для проведения платежа также может потребоваться ввод специального пароля.</p>
    <p>Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО «СБЕРБАНК». Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.</p>
  </div>
)
