import React from 'react'
import MediaQuery from 'react-responsive'
import mediaSize from '../../../constants/mediaSize'

import { Button } from '../../../Components'

import styles from './styles.module.scss'

export default () => {
  const invite = localStorage.getItem('invite')
  return (
    <header className={styles.wrap}>
      <div className="base-layout">
        <div className={styles.content}>
          <div className={styles.contentLeft}>
            <h1 className={styles.title}>
              { 'Платежи\u00A0+ организации' }
            </h1>
            <p className={styles.text}>
              {'Принимаем платежи по всему миру, делаем массовые выплаты '
              + 'самозанятым с\u00A0расчётного счёта или за\u00A0счёт платежей.'}
            </p>
            <Button
              isExternalLink
              isNotBlank
              type="yellow"
              wrapStyle={styles.button}
              to={`https://lk.tome.ge/signup?role=business${invite ? `?invite=${invite}` : ''}`}
              onClick={() => localStorage.removeItem('invite')}
            >
              { 'Подключиться'.toUpperCase() }
            </Button>
          </div>
          <MediaQuery minWidth={mediaSize.maxPhoneSize}>
            <div className={styles.contentRight}>
              <i className={styles.illustration} />
              <img
                alt="blob"
                src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/business/header/blob.png`}
                width={151}
                height={140}
                className={styles.blob}
              />
            </div>
            <img
              alt="blobMiddle"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/business/header/blobMiddle.png`}
              width={140}
              height={129}
              className={styles.blobMiddle}
            />
            <img
              alt="blobBig"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/business/header/blobBig.png`}
              width={214}
              height={198}
              className={styles.blobBig}
            />
            <img
              alt="blobSmall"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/business/header/blobSmall.png`}
              width={106}
              height={103}
              className={styles.blobSmall}
            />
          </MediaQuery>
        </div>
      </div>
    </header>
  )
}
