import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

export default class DottedLine extends React.Component {
  static propTypes = {
    isFreelance: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.wrapRef = React.createRef()
    this.state = {
      dotCount: 0,
    }
  }

  componentDidMount() {
    this.setDotCount()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFreelance !== this.props.isFreelance) {
      this.setDotCount()
    }
  }

  setDotCount = () => {
    this.setState({ dotCount: Math.floor(this.wrapRef.current.clientWidth / 6) })
  }

  render() {
    const { dotCount } = this.state
    return (
      <div ref={this.wrapRef} className={styles.wrap}>
        {dotCount > 0 && [...Array(dotCount)].map(
          (dot, index) => <i key={index} className={styles.dot} /> // eslint-disable-line
        )}
      </div>
    )
  }
}
