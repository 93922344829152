import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import MediaQuery from 'react-responsive'
import mediaSize from '../../constants/mediaSize'

import {
  InnerPageHeader,
  Connection,
  FooterWrap,
  ButtonTabs,
} from '../../Components'

import Conditions from './Conditions'
import Payments from './Payments'

import styles from './styles.module.scss'

export default class Tariffs extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      hash: PropTypes.string,
    }),
  }

  constructor(props) {
    super(props)
    this.state = {
      isShowTitle: !props.location.hash.length,
      activeTab: 'Фрилансерам',
    }
  }

  componentDidMount() {
    setTimeout(() => NProgress.done(), 300)
  }

  componentDidUpdate() {
    this.setIsShowTitle(this.props)
  }

  componentWillUnmount() {
    NProgress.start()
  }

  setIsShowTitle = props => this.setState({ isShowTitle: !props.location.hash.length })

  handleChangeTab = ({ name: activeTab }) => this.setState((prevState) => {
    if (prevState.activeTab !== activeTab) {
      return {
        activeTab,
      }
    }
    return prevState
  })

  render() {
    const {
      isShowTitle,
      activeTab,
    } = this.state
    const { handleChangeTab } = this

    return (
      <>
        {isShowTitle && (
          <Helmet>
            <title>Тарифы :. tome</title>
            <meta property="og:title" content="Тарифы на приём платежей и выплаты" />
            <meta property="og:url" content="https://tome.ge/tariffs" />
          </Helmet>
        )}
        <div className={styles.block}>
          <MediaQuery maxWidth={mediaSize.maxPhoneSize - 1}>
            <img
              alt="waveMobile"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/wave_header_bottom_mobile.webp`}
              className={styles.headerImageMobile}
            />
          </MediaQuery>
          <InnerPageHeader
            title={'Прозрачно, выгодно,\u00A0просто'}
            text={(
              <>
                Максимально быстро, с минимумом бюрократии.
                <br />
                Так проще, так лучше, так быстрее.
              </>
            )}
          >
            {/*
            <p className={styles.hashTag}>
              <img alt="honeyPot" src={honeyPot} width={24} height={24} />
              <strong className={styles.hashTagText}>#sweetrates ..</strong>
            </p>
            */}
            <div className={styles.tabsWrap}>
              <ButtonTabs
                tabs={[{ name: 'Фрилансерам' }, { name: 'Организациям' }]}
                currentTab={activeTab}
                handlePress={handleChangeTab}
              />
            </div>
          </InnerPageHeader>
        </div>
        <Conditions activeTab={activeTab} />
        <div className={styles.mobileBottom}>
          <img
            alt="waveMobile"
            src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/wave_mobile_footer_top.webp`}
            className={styles.waveMobileTop}
          />
          <Payments activeTab={activeTab} />
          <MediaQuery maxWidth={mediaSize.maxPhoneSize - 1}>
            <Connection />
          </MediaQuery>
        </div>
        <FooterWrap>
          <MediaQuery minWidth={mediaSize.maxPhoneSize}>
            <Connection />
          </MediaQuery>
        </FooterWrap>
      </>
    )
  }
}
