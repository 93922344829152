import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import mediaSize from '../../constants/mediaSize'

import Footer from '../Footer'

import styles from './styles.module.scss'

const FooterWrap = props => (
  <section className={styles.wrap}>
    <MediaQuery minWidth={mediaSize.maxPhoneSize}>
      <img
        alt="wave"
        src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/footer/wave.webp`}
        className={styles.wave}
      />
    </MediaQuery>
    { props.children }
    <Footer />
  </section>
)

FooterWrap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
}

export default FooterWrap
