import React from 'react'
import PropTypes from 'prop-types'
import { parse } from 'query-string'
import MediaQuery from 'react-responsive'
import mediaSize from '../../constants/mediaSize'

import { Documents } from '../../Pages'
import Header from '../Header'
import MobileMenu from '../MobileMenu'

import styles from './styles.module.scss'

class LandingWrap extends React.PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
    location: PropTypes.shape({
      hash: PropTypes.string,
      search: PropTypes.string,
    }),
  }

  getSearchOptions = (searchString = '') => parse(searchString, { arrayFormat: 'index' })

  render() {
    const {
      children,
      location: {
        hash,
        search,
      },
    } = this.props

    const searchOptions = this.getSearchOptions(search)

    if (searchOptions.invite) {
      const { invite } = searchOptions
      localStorage.setItem('invite', invite)
    }

    return (
      <div className={styles.wrap}>
        {hash.length ? <Documents /> : null}
        <Header {...this.props} />
        {children}
        <MediaQuery maxWidth={mediaSize.maxPhoneSize - 1}>
          <MobileMenu {...this.props} />
        </MediaQuery>
      </div>
    )
  }
}

export default LandingWrap
