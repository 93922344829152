import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const ApiInformation = ({ isBusiness }) => {
  const texts = {
    selfEmployer: [
      {
        title: 'Чеки',
        text: 'Интеграция с ФНС. Легко получить чек, создать или изменить его.',
      },
      {
        title: 'Платежи',
        text: 'Карты РФ, СНГ, Европы, кошельки Киви, Юмоней, оплата QR-кодом.',
      },
      {
        title: 'Выплаты',
        text: 'На карты РФ, СНГ, Европы, кошельки Qiwi, Юмоней, по номеру телефона.',
      },
    ],
    business: [
      {
        title: 'Расчёты',
        text: 'На карты РФ, СНГ, Европы, кошельки Qiwi, Юмоней, по номеру телефона.',
      },
      {
        title: 'Самозанятые',
        text: 'Полная интеграция с ФНС. Справки, налоги, фиксация дохода и чеки.',
      },
      {
        title: 'Модерация',
        text: 'Получение, обработка и проверка информации о каждой сделке.',
      },
    ],
  }

  return (
    <div className={styles.wrap}>
      <img
        alt="wave"
        src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/apiInformation/wave.webp`}
        className={styles.wave}
        width={4096}
        height={253}
      />
      <div className="base-layout">
        <img
          alt="pattern"
          src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/apiInformation/pattern.svg`}
          className={styles.pattern}
          width={928}
          height={527}
        />
        <img
          alt="blobs"
          src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/apiInformation/blobs.png`}
          className={styles.blobs}
          width={198}
          height={257}
        />
        <div className={styles.contentTop}>
          <h2 className={styles.title}>
            { 'Мощное API +' }
          </h2>
          <p className={styles.text}>
            { 'Служба технической поддержки, гибкий SDK для популярных '
            + 'языков программирования, интеграция с\u00A0известными CMS.' }
          </p>
          <a
            href="https://docs.tome.ge/"
            className={styles.titleLink}
          >
            { 'Документация' }
            <i className={styles.titleLinkImage} />
          </a>
        </div>
        <div className={styles.reasonsWrap}>
          {texts[isBusiness ? 'business' : 'selfEmployer'].map(reason => (
            <div className={styles.reason}>
              <h4 className={styles.reasonTitle}>
                {reason.title}
              </h4>
              <p className={styles.reasonText}>
                {reason.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

ApiInformation.propTypes = {
  isBusiness: PropTypes.bool,
}

export default ApiInformation
