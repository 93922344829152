import React from 'react'
import PropTypes from 'prop-types'
import MediaQuery from 'react-responsive'
import classNames from 'classnames'

import mediaSize from '../../../constants/mediaSize'

import { DottedLine } from '../../../Components'

import styles from './styles.module.scss'

const Payments = ({ activeTab }) => {
  const isFreelance = activeTab === 'Фрилансерам'

  return (
    <div className={styles.wrap}>
      <MediaQuery minWidth={mediaSize.maxPhoneSize}>
        <img
          alt="shadowWave"
          src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/tariffs/payments/shadow_wave.svg`}
          className={styles.shadowWave}
        />
      </MediaQuery>
      <div className="base-layout">
        <MediaQuery minWidth={mediaSize.maxPhoneSize}>
          <img
            alt="illustration"
            src={
              isFreelance
                ? `https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/tariffs/payments/percent_5.webp`
                : `https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/tariffs/payments/percent_1.webp`}
            className={styles.illustration}
          />
          <img
            alt="bubble"
            width={220}
            src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/tariffs/payments/bubble.png`}
            className={styles.bubble}
          />
        </MediaQuery>
        <div className={styles.info}>
          <h2 className={styles.title}>
            { isFreelance ? 'Выплаты от сделок' : 'Массовые выплаты' }
          </h2>
          <p className={styles.text}>
            {isFreelance
              ? 'Получайте деньги от сделок на вашу карту, '
              + 'электронный кошелёк или по номеру телефона через систему быстрых платежей.'
              : 'Отправляйте деньги от сделок на карты, '
              + 'электронные кошельки или по\u00A0номеру телефона через систему быстрых платежей.'
            }
          </p>
        </div>
        <div className={styles.contentWrap}>
          <div className={styles.contentRow}>
            <div className={styles.content}>
              <div className={styles.box}>
                <div className={styles.boxLogosWarp}>
                  <i className={classNames('i_visa_l', styles.boxLogo)} />
                  <i className={classNames('i_mastercard_l', styles.boxLogo)} />
                  <i className={classNames('i_mir_l', styles.boxLogo)} />
                </div>
                <div className={styles.boxInfo}>
                  <p className={styles.boxName}>
                    { 'Банковские\u00A0карты' }
                  </p>
                  <DottedLine {...{ isFreelance }} />
                  <p className={styles.boxPercentWrap}>
                    <span className={styles.boxPercent}>{isFreelance ? '5' : '2'}</span>
                    { '%' }
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.box}>
                <div className={styles.boxLogosWarp}>
                  <i className={classNames('i_ustd', styles.boxLogo)} />
                </div>
                <div className={styles.boxInfo}>
                  <p className={styles.boxName}>
                    { 'USDT Кошелёк' }
                  </p>
                  <DottedLine {...{ isFreelance }} />
                  <p className={styles.boxPercentWrap}>
                    <span className={styles.boxPercent}>{isFreelance ? '9' : '7'}</span>
                    { '%' }
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.content} />
          </div>
          <div className={styles.contentRow}>
            <div className={styles.content}>
              <div className={styles.box}>
                <div className={styles.boxLogosWarp}>
                  <i className={classNames('i_qiwi_l', styles.boxLogo)} />
                </div>
                <div className={styles.boxInfo}>
                  <p className={styles.boxName}>
                    { 'Qiwi\u00A0Кошелек ' }
                  </p>
                  <DottedLine {...{ isFreelance }} />
                  <p className={styles.boxPercentWrap}>
                    <span className={styles.boxPercent}>{isFreelance ? '~' : '1.5'}</span>
                    { '%' }
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.box}>
                <div className={styles.boxLogosWarp}>
                  <i className={classNames('i_yoomoney_l', styles.boxLogo)} />
                </div>
                <div className={styles.boxInfo}>
                  <p className={styles.boxName}>
                    { 'ЮMoney' }
                  </p>
                  <DottedLine {...{ isFreelance }} />
                  <p className={styles.boxPercentWrap}>
                    <span className={styles.boxPercent}>{isFreelance ? '~' : '1.5'}</span>
                    { '%' }
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.box}>
                <div className={styles.boxLogosWarp}>
                  <i className={classNames('i_sbp', styles.boxLogo)} />
                </div>
                <div className={styles.boxInfo}>
                  <p className={styles.boxName}>
                    { 'СБП-перевод' }
                  </p>
                  <DottedLine {...{ isFreelance }} />
                  <p className={styles.boxPercentWrap}>
                    <span className={styles.boxPercent}>{isFreelance ? '~' : '1.5'}</span>
                    { '%' }
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Payments.propTypes = {
  activeTab: PropTypes.oneOf(['Фрилансерам', 'Организациям']).isRequired,
}

export default Payments
