import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

import Wave from '../../constants/wave'

import styles from './styles.module.scss'

export default class Button extends React.PureComponent {
  static propTypes = {
    buttonType: PropTypes.string,
    type: PropTypes.oneOf([
      'yellow',
      'white',
    ]),
    size: PropTypes.oneOf([
      'default',
      'small',
      'large',
    ]),
    icon: PropTypes.element,
    wrapStyle: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    onClick: PropTypes.func,
    isDisable: PropTypes.bool,
    isLoading: PropTypes.bool,
    isExternalLink: PropTypes.bool,
    isNotBlank: PropTypes.bool,
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string,
        state: PropTypes.shape({}),
      }),
    ]),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element,
      PropTypes.string,
    ]),
  }

  static defaultProps = {
    to: '',
    type: 'white',
    buttonType: 'button',
    size: 'default',
    isDisable: false,
    isLoading: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    const {
      type,
      buttonType,
      size,
      wrapStyle,
      onClick,
      icon,
      children,
      isDisable,
      isLoading,
      isExternalLink,
      isNotBlank,
      to,
    } = this.props

    let button = (
      <button
        type={buttonType}
        className={classNames(
          styles.button,
          styles[type],
          styles[`${size}Size`],
          isDisable && styles[`${type}Disable`],
          isLoading && styles[`${type}Loading`],
          wrapStyle
        )}
        onClick={onClick}
      >
        {icon && (
          <span
            className={classNames(
              styles.iconWrap,
              children && styles.iconWrapMargin
            )}
          >
            {icon}
          </span>
        )}
        {children && (
          <span className={styles.text}>
            {children}
          </span>
        )}
      </button>
    )

    if (isExternalLink && typeof to === 'string') {
      button = (
        <a
          href={to}
          target={isNotBlank ? '_parent' : '_blank'}
          rel="noopener noreferrer"
          className={classNames(
            styles.button,
            styles.buttonLink,
            styles[type],
            styles[`${size}Size`],
            isDisable && styles[`${type}Disable`],
            isLoading && styles[`${type}Loading`],
            wrapStyle
          )}
          onClick={onClick}
        >
          {icon && (
            <span
              className={classNames(
                styles.iconWrap,
                children && styles.iconWrapMargin
              )}
            >
              {icon}
            </span>
          )}
          {children && (
            <span className={styles.text}>
              {children}
            </span>
          )}
        </a>
      )
    }

    if (!isExternalLink && (to.length || typeof to === 'object')) {
      button = (
        <NavLink
          to={to}
          className={classNames(
            styles.button,
            styles.buttonLink,
            styles[type],
            styles[`${size}Size`],
            isDisable && styles[`${type}Disable`],
            isLoading && styles[`${type}Loading`],
            wrapStyle
          )}
          onClick={onClick}
        >
          {icon && (
            <span
              className={classNames(
                styles.iconWrap,
                children && styles.iconWrapMargin
              )}
            >
              {icon}
            </span>
          )}
          {children && (
            <span className={styles.text}>
              {children}
            </span>
          )}
        </NavLink>
      )
    }

    return (
      <Wave>
        {button}
      </Wave>
    )
  }
}
