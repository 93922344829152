import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import MediaQuery from 'react-responsive'
import mediaSize from '../../constants/mediaSize'
import classNames from 'classnames'

import styles from './styles.module.scss'

import Header from './Header'
import Advantages from './Advantages'

import {
  Connection,
  ApiInformation,
  FooterWrap,
} from '../../Components'

export default class Business extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      hash: PropTypes.string,
    }),
  }

  constructor(props) {
    super(props)
    this.state = {
      isShowTitle: !props.location.hash.length,
    }
  }

  componentDidMount() {
    setTimeout(() => NProgress.done(), 300)
  }

  componentDidUpdate() {
    this.setIsShowTitle(this.props)
  }

  componentWillUnmount() {
    NProgress.start()
  }

  setIsShowTitle = props => this.setState({ isShowTitle: !props.location.hash.length })

  render() {
    const { isShowTitle } = this.state

    return (
      <>
        {isShowTitle && (
          <Helmet>
            <title>Для маркетплейсов работ и услуг :. tome</title>
            <meta property="og:title" content="Для маркетплейсов работ и услуг" />
            <meta property="og:url" content="https://tome.ge/business" />
          </Helmet>
        )}
        <section className={styles.headerWrap}>
          <MediaQuery minWidth={mediaSize.maxPhoneSize}>
            <i className={classNames('i_waveHeaderBG', styles.headerImage)} />
          </MediaQuery>
          <MediaQuery maxWidth={mediaSize.maxPhoneSize - 1}>
            <img
              alt="waveMobile"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/wave_header_bottom_mobile.webp`}
              className={styles.headerImageMobile}
            />
          </MediaQuery>
          <Header />
        </section>
        <Advantages />
        <MediaQuery minWidth={mediaSize.maxPhoneSize}>
          <ApiInformation isBusiness />
        </MediaQuery>
        <FooterWrap>
          <Connection isBusiness isHome />
        </FooterWrap>
      </>
    )
  }
}
