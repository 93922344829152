import React, { lazy, Suspense } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import { LandingWrap } from '../Components'

const NotFound = lazy(() => import('../Pages/NotFound'))
const Home = lazy(() => import('../Pages/Home'))
const Business = lazy(() => import('../Pages/Business'))
const Contacts = lazy(() => import('../Pages/Contacts'))
const Tariffs = lazy(() => import('../Pages/Tariffs'))

const Routes = props => (
  <LandingWrap {...props}>
    <Suspense fallback={null}>
      <Switch>
        <Route
          exact
          path="/"
          component={Home}
        />
        <Route
          exact
          path="/business"
          component={Business}
        />
        <Route
          exact
          path="/contacts"
          component={Contacts}
        />
        <Route
          exact
          path="/tariffs"
          component={Tariffs}
        />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  </LandingWrap>
)

export default withRouter(Routes)
