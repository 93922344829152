import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.scss'

export default class CardCarousel extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
  }

  state = {
    showCardIndex: 0,
    showingCardIndex: null,
    exitingCardIndex: null,
    isAnimation: false,
  }

  handleSecondCard = () => {
    const lastIndex = this.props.children.length - 1
    const {
      showCardIndex,
    } = this.state

    this.goToSecondCard({
      exitingCardIndex: showCardIndex,
      showCardIndex: showCardIndex === lastIndex ? 0 : showCardIndex + 1,
    })
  }

  handleChooseTab = (tabNumber) => {
    const {
      showCardIndex,
    } = this.state
    if (tabNumber - 1 !== showCardIndex) {
      this.goToSecondCard({
        exitingCardIndex: showCardIndex,
        showCardIndex: tabNumber - 1,
      })
    }
  }

  goToSecondCard = ({ exitingCardIndex, showCardIndex }) => {
    this.setState({
      showingCardIndex: showCardIndex,
      exitingCardIndex,
      isAnimation: true,
    }, () => {
      setTimeout(() => this.setState({
        showCardIndex,
        showingCardIndex: null,
        exitingCardIndex: null,
        isAnimation: false,
      }), 310)
    })
  }

  render() {
    const { children } = this.props
    const {
      showCardIndex,
      showingCardIndex,
      exitingCardIndex,
      isAnimation,
    } = this.state
    return (
      <div className={styles.wrap}>
        <div className={classNames(styles.cardBack, isAnimation && styles.cardBackAnimation)}>
          <div className={styles.cardBackground} />
        </div>
        {children.map((child, index) => (
          <div
            key={index}
            className={classNames(
              styles.card,
              isAnimation && styles.cardAnimation,
              showCardIndex === index && styles.cardShow,
              showingCardIndex === index && styles.cardShowing,
              exitingCardIndex === index && styles.cardExiting
            )}
          >
            <div className={styles.cardBackground} />
            <div className={styles.cardContent}>
              {child}
            </div>
          </div>
        ))}
      </div>
    )
  }
}
