import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.scss'

const CircleWithIcon = (props) => {
  const {
    children,
    onClick,
    wrapClass,
  } = props
  return (
    <button
      onClick={onClick}
      className={classNames(
        styles.circle,
        onClick && styles.circleButton,
        wrapClass
      )}
    >
      { children }
    </button>
  )
}

CircleWithIcon.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  wrapClass: PropTypes.string,
  onClick: PropTypes.func,
}

export default CircleWithIcon
