import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import MediaQuery from 'react-responsive'
import mediaSize from '../../constants/mediaSize'

import Button from '../Button'

import styles from './styles.module.scss'

const Header = (props) => {
  const { pathname } = props.location
  const invite = localStorage.getItem('invite')
  return (
    <menu className={styles.wrap}>
      <div className="base-layout">
        <div className={styles.content}>
          <div className={styles.contentLeft}>
            <Link to="/" className={styles.logoWrap}>
              <img
                alt="Tome"
                src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/logo.svg`}
                height={28}
                width={80}
              />
            </Link>
            <MediaQuery minWidth={mediaSize.maxPhoneSize}>
              <div className={styles.menu}>
                <Link
                  to={pathname === '/business' ? '/' : '/business'}
                  className={classNames(
                    styles.menuItem,
                    styles.menuItemBusiness,
                    pathname === '/business' && styles.menuItemBusinessActive
                  )}
                >
                  { 'Бизнес' }
                </Link>
                <a
                  href="https://docs.tome.ge/"
                  className={styles.menuItem}
                >
                  { 'API' }
                </a>
                <Link
                  to="/tariffs"
                  className={classNames(
                    styles.menuItem,
                    pathname === '/tariffs' && styles.menuItemSelect
                  )}
                >
                  { 'Тарифы' }
                </Link>
              </div>
            </MediaQuery>
          </div>
          <MediaQuery minWidth={mediaSize.maxPhoneSize}>
            <div className={styles.contentRight}>
              <Link
                to="/contacts"
                className={classNames(
                  styles.menuItem,
                  pathname === '/contacts' && styles.menuItemSelect
                )}
              >
                { 'Контакты' }
              </Link>
              <Button
                isExternalLink
                isNotBlank
                to={`https://lk.tome.ge/signin${invite ? `?invite=${invite}` : ''}`}
                onClick={() => localStorage.removeItem('invite')}
                wrapStyle={styles.buttonSignIn}
              >
                <span>
                  { 'Войти'.toUpperCase() }
                </span>
                <i className={styles.buttonSignInImage} />
              </Button>
            </div>
          </MediaQuery>
        </div>
      </div>
    </menu>
  )
}

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export default Header
