import React from 'react'
import { Helmet } from 'react-helmet'

import { Button } from '../../../../Components'

import img1 from './img/img_1.png'
import img2 from './img/img_2.png'

export default () => (
  <div className="document">
    <Helmet>
      <title>Номинальный счёт :. tome</title>
      <meta property="og:title" content="Номинальный счёт" />
      <meta property="og:url" content="https://tome.ge/#document-nominal-bill-bank131" />
      <meta property="og:type" content="article" />
    </Helmet>
    <aside>
      <span>29 октября 2021 г.</span>
    </aside>
    <h2>Номинальный счёт</h2>
    <p>Счёт предназначен для выплат самозанятым, за предоставленные услуги, на карты, открытые в любом банке.  Все деньги на счёте принадлежат организации (бенефициару). Никаких закрывающих документов после пополнения номинального счёта вам не потребуется. При расчетах с самозанятыми не забудьте сформировать фискальный чек, это можно сделать в личном кабинете или по API.</p>
    <div className="document_buttons">
      <Button
        isExternalLink
        to="https://docs.tome.ge/"
        size="large"
        wrapStyle="document_button"
      >
        { 'Документация к API' }
      </Button>
    </div>
    <h3>Начало работы</h3>
    <p>Для работы с номинальным счётом необходимо в личном кабинете организации, в настройках профиля выбрать «Привязанные счета». Среди привязанных счетов найдите «Номинальный» и включите его.</p>
    <div className="document_pick_paddings">
      <img alt="bills" src={img1} className="document_pick" />
    </div>
    <h3>Заключение договора</h3>
    <p>После включения номинального счета в личном кабинете организации, в разделе «Документы» появится новый договор «Соглашение №XXXX». Скачайте его, подпишите и загрузите скан-копию, оригинал отправлять не нужно.</p>
    <div className="document_pick_paddings">
      <img alt="docs" src={img2} className="document_pick" />
    </div>
    <h3>Реквизиты для пополнения</h3>
    <p>Нашим расчётным банком является «Банк 131». При пополнении баланса, в назначении платежа не забудьте указать номер вашего договора.</p>
    <p>
      <b>ООО ТОМЕ</b>
      <span>ИНН 3666233589, ОГРН 1193668003583</span>
      <span>Номинальный счет: 40702810700200000024</span>
      <span>БИК Банка: 049205131</span>
      <span>Кор/счет: 30101810822029205131</span>
      <span>Банк Получателя: ООО «Банк 131»</span>
    </p>
    <p>
      <span><b>Назначение платежа:</b></span>
      <span>Перечисление денежных средств согласно договору №XXX для расчетов с самозанятыми.</span>
    </p>
  </div>
)
