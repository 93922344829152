export default function Parallax(parallaxElementsList) {
  let elements = []
  let screenHeight
  let animationId
  let isAnimating

  window.requestAnimationFrame = window.requestAnimationFrame
    || window.mozRequestAnimationFrame
    || window.webkitRequestAnimationFrame
    || window.msRequestAnimationFrame
    || function (f) {
      return setTimeout(f, 1000 / 60)
    }

  const cancelAnimationFrame = window.cancelAnimationFrame
    || window.mozCancelAnimationFrame
    || clearTimeout

  const animate = () => {
    if (!isAnimating) {
      isAnimating = true
      elements.forEach(({
        el,
        originalTop,
        height,
        speed,
      }) => {
        const { top: newTop } = el.getBoundingClientRect()
        let translate
        if (screenHeight >= originalTop) {
          translate = Math.floor((window.scrollY / speed) * -1)
        } else {
          translate = Math.floor((newTop - height / 2) / speed)
        }

        el.style.transform = `translate3d(0, ${translate}px, 0)`
      })
      isAnimating = false
      animationId = requestAnimationFrame(animate)
    }
  }

  const setup = () => {
    // Cleanup before setting up new requestAnimationFrame.
    cancelAnimationFrame(animationId)
    if (
      !parallaxElementsList.length
      || (parallaxElementsList[0] && !parallaxElementsList[0].current)
    ) {
      // "No parallax elements found."
      return
    }

    elements = parallaxElementsList.map((el) => {
      if (el.current) {
        const { top, height } = el.current.getBoundingClientRect()
        const speed = Number(el.current.getAttribute('data-parallax'))
        return {
          el: el.current,
          originalTop: top + window.scrollY,
          height,
          speed,
        }
      }
      return null
    })
    screenHeight = window.innerHeight

    animate()
  }

  const cleanup = () => {
    cancelAnimationFrame(animationId)
    window.removeEventListener('resize', setup)
  }

  const init = () => {
    setup()
    window.addEventListener('resize', setup)
  }

  return {
    init,
    cleanup,
  }
}
