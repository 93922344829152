import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import MediaQuery from 'react-responsive'
import mediaSize from '../../constants/mediaSize'

import {
  InnerPageHeader,
  FooterWrap,
} from '../../Components'

import Information from './Information'
import Licenses from './Licenses'
import Questions from './Questions'

import styles from './styles.module.scss'

export default class Contacts extends PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      hash: PropTypes.string,
    }),
  }

  constructor(props) {
    super(props)
    this.state = {
      isShowTitle: !props.location.hash.length,
    }
  }

  componentDidMount() {
    setTimeout(() => NProgress.done(), 300)
  }

  componentDidUpdate() {
    this.setIsShowTitle(this.props)
  }

  componentWillUnmount() {
    NProgress.start()
  }

  setIsShowTitle = props => this.setState({ isShowTitle: !props.location.hash.length })

  render() {
    const { isShowTitle } = this.state

    return (
      <>
        {isShowTitle && (
          <Helmet>
            <title>Контакты :. tome</title>
            <meta property="og:title" content="Контакты" />
            <meta property="og:url" content="https://tome.ge/contacts" />
          </Helmet>
        )}
        <div className={styles.block}>
          <MediaQuery maxWidth={mediaSize.maxPhoneSize - 1}>
            <img
              alt="waveMobile"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/wave_header_bottom_mobile.webp`}
              className={styles.headerImageMobile}
            />
          </MediaQuery>
          <InnerPageHeader
            title={'24 часа для большого дела'}
            text={'Программисты, дизайнеры, копирайтеры, служба поддержки - одна большая семья.'}
          >
            <div className={styles.followWrap}>
              <a
                href="https://www.instagram.com/tmpublic/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.follow}
              >
                <i className="i_instagram_full" />
                <strong className={styles.followText}>tmpublic</strong>
              </a>
            </div>
          </InnerPageHeader>
        </div>
        <Information />
        <div className={styles.mobileBottom}>
          <img
            alt="waveMobile"
            src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/wave_mobile_footer_top.webp`}
            className={styles.waveMobileTop}
          />
          <Licenses />
          <MediaQuery maxWidth={mediaSize.maxPhoneSize - 1}>
            <Questions />
          </MediaQuery>
        </div>
        <FooterWrap>
          <MediaQuery minWidth={mediaSize.maxPhoneSize}>
            <Questions />
          </MediaQuery>
        </FooterWrap>
      </>
    )
  }
}
