import React from 'react'
import MediaQuery from 'react-responsive'
import mediaSize from '../../../../constants/mediaSize'

import { Button } from '../../../../Components'

import styles from './styles.module.scss'

export default () => {
  const invite = localStorage.getItem('invite')
  return (
    (
      <header className={styles.wrap}>
        <div className="base-layout">
          <div className={styles.content}>
            <div className={styles.contentLeft}>
              <h1 className={styles.title}>
                { 'Платежи\u00A0+ фрилансеры' }
              </h1>
              <p className={styles.text}>
                {'Отлично подходит для работы с\u00A0заказчиками, удобно получить, '
                + 'быстро снять, по видеочату или\u00A0через выставленный счёт.'}
              </p>
              <Button
                type="yellow"
                size="large"
                wrapStyle={styles.button}
                to={`https://lk.tome.ge/signup${invite ? `?invite=${invite}` : ''}`}
                onClick={() => localStorage.removeItem('invite')}
                isExternalLink
                isNotBlank
              >
                { 'Подключиться'.toUpperCase() }
              </Button>
            </div>
            <MediaQuery minWidth={mediaSize.maxPhoneSize}>
              <div className={styles.contentRight}>
                <i className={styles.man} />
                <img
                  alt="blobSmall"
                  src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/home/header/blob_small.png`}
                  className={styles.blobSmall}
                  width={154}
                  height={147}
                />
              </div>
            </MediaQuery>
          </div>
        </div>
      </header>
    )
  )
}
