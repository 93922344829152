import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import MediaQuery from 'react-responsive'
import mediaSize from '../../../../constants/mediaSize'

import {
  CircleWithIcon,
  CardCarousel,
} from '../../../../Components'

import styles from './styles.module.scss'

import cardFirst from './img/cards/cardFirst.svg'
import cardSecond from './img/cards/cardSecond.svg'
import cardThird from './img/cards/cardThird.svg'
import cardFourth from './img/cards/cardFourth.svg'
import cardFifth from './img/cards/cardFifth.svg'

import arrowDown from './img/arrow_down_black.svg'
import arrowRightSmall from '../../../../img/arrow_small_right_black.svg'
import wave from './img/wave.svg'
import waveBlobBig from './img/blob_big.png'
import waveBlobsSmall from './img/blob_small.png'

export default class About extends React.PureComponent {
  constructor(props) {
    super(props)
    this.carouselRef = React.createRef()
  }

  state = {
    activeTab: 'work',
    isCardAnimated: false,
  }

  handleChangeActiveTab = activeTab => this.setState((prevState) => {
    switch (activeTab) {
      case 'work':
        this.carouselRef.current.handleChooseTab(1)
        break
      case 'life':
        this.carouselRef.current.handleChooseTab(4)
        break
      default:
        break
    }
    if (prevState.activeTab !== activeTab) {
      return { activeTab }
    }
    return prevState
  })

  handleChangeCard = () => {
    const { isCardAnimated } = this.state
    if (!isCardAnimated) {
      this.setState({ isCardAnimated: true }, () => {
        this.carouselRef.current.handleSecondCard()
        setTimeout(() => this.setState({ isCardAnimated: false }), 400)
      })
    }
  }

  render() {
    const { activeTab } = this.state
    const {
      handleChangeActiveTab,
      handleChangeCard,
    } = this

    return (
      <section className={styles.wrap}>
        <div className="base-layout">
          <div className={styles.content}>
            <MediaQuery minWidth={mediaSize.maxPhoneSize}>
              <div className={styles.contentLeft}>
                <div className={styles.cardWrap}>
                  <CardCarousel ref={this.carouselRef}>
                    <img alt="cardFirst" src={cardFirst} className={styles.cardFirst} />
                    <img alt="cardSecond" src={cardSecond} className={styles.cardSecond} />
                    <img alt="cardThird" src={cardThird} className={styles.cardThird} />
                    <img alt="cardFourth" src={cardFourth} className={styles.cardFourth} />
                    <img alt="cardFifth" src={cardFifth} className={styles.cardFifth} />
                  </CardCarousel>
                  <CircleWithIcon
                    onClick={handleChangeCard}
                    wrapClass={styles.maskArrow}
                  >
                    <img alt="arrowDown" src={arrowDown} />
                  </CircleWithIcon>
                </div>
              </div>
            </MediaQuery>
            <div className={styles.contentRight}>
              <div className={styles.tabButtonsWrap}>
                <button
                  className={classNames(styles.tab, activeTab !== 'work' && styles.tabHide)}
                  onClick={() => handleChangeActiveTab('work')}
                >
                  <span>
                    { 'Для работы'.toUpperCase() }
                  </span>
                </button>
                <button
                  className={classNames(styles.tab, activeTab !== 'life' && styles.tabHide)}
                  onClick={() => handleChangeActiveTab('life')}
                >
                  <span>
                    { 'Для жизни'.toUpperCase() }
                  </span>
                </button>
              </div>
              <div className={classNames(styles.tabContent, activeTab !== 'work' && styles.tabContentHide)}>
                <p className={styles.tabText}>
                  { 'Заключайте безопасные сделки, работайте с\u00A0юр.\u00A0лицами, '
                  + 'ведите бухгалтерию, принимайте платежи с\u00A0карт, '
                  + 'электронных кошельков и\u00A0мобильных телефонов.' }
                </p>
                <Link
                  to="/"
                  className={styles.tabLink}
                >
                  { 'Посмотреть тарифы' }
                  <img alt="arrowRight" src={arrowRightSmall} />
                </Link>
              </div>
              <div className={classNames(styles.tabContent, activeTab !== 'life' && styles.tabContentHide)}>
                <p className={styles.tabText}>
                  { 'Заключайте безопасные сделки, работайте с\u00A0юр.\u00A0лицами, '
                  + 'ведите бухгалтерию, принимайте платежи с\u00A0карт, '
                  + 'электронных кошельков и\u00A0мобильных телефонов.' }
                </p>
                <Link
                  to="/"
                  className={styles.tabLink}
                >
                  { 'Узнайте больше' }
                  <img alt="arrowRight" src={arrowRightSmall} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <MediaQuery minWidth={mediaSize.maxPhoneSize}>
          <img alt="wave" src={wave} className={styles.wave} />
          {/*
          <img alt="waveBlobs" src={waveBlobs} className={styles.waveBlobs} />
          */}
          <img alt="waveBlobBig" src={waveBlobBig} className={styles.waveBlobBig} />
          <img alt="waveBlobsSmall" src={waveBlobsSmall} className={styles.waveBlobsSmall} />
        </MediaQuery>
      </section>
    )
  }
}
