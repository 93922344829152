import React from 'react'
import { Helmet } from 'react-helmet'

import img1 from './img/img_1.png'
import img2 from './img/img_2.png'
import img3 from './img/img_3.png'

export default () => (
  <div className="document">
    <Helmet>
      <title>Инструкция: подключение к электронной площадке :. tome</title>
      <meta property="og:title" content="Инструкция: подключение к электронной площадке" />
      <meta property="og:url" content="https://tome.ge/#document-selfEmployer-answer" />
      <meta property="og:type" content="article" />
    </Helmet>
    <aside>
      <span>3 декабря 2019 г.</span>
    </aside>
    <h2>Инструкция: подключение к электронной площадке</h2>
    <p>Чтобы предоставить доступ электронной площадке, нужно пройти регистрацию и получить статус самозанятого. Без регистрации подключение к площадке и формирование чеков невозможно.</p>
    <h3>Через приложение</h3>
    <p>Электронная площадка по номеру телефона или ИНН направляет запрос на подключение. В приложении «Мой налог» в разделе «Сообщения» появляется новое уведомление.</p>
    <p>Перейдите к содержанию нового уведомления и в конце сообщения обратите внимание на ссылку – «Перейти в раздел партнеров».</p>
    <img alt="selfEmployed" src={img1} className="document_pick" />
    <p>В разделе «Программы партнеры» среди списка доступных банков и электронных площадок появится «запрос на подключение».</p>
    <img alt="selfEmployed" src={img2} className="document_pick" />
    <p>После ответа на запрос, вернитесь в личный кабинет и ожидайте обновления статуса подключения.</p>
    <h3>Через личный кабинет</h3>
    <p>Электронная площадка по номеру телефона или ИНН направляет запрос на подключение. В личном кабинете налогоплательщика НПД «Мой налог» в разделе «Настройки» выберите подраздел «Партнеры».</p>
    <p>Среди списка доступных банков и электронных площадок появится «запрос на подключение».</p>
    <img alt="selfEmployed" src={img3} className="document_pick" />
    <p>После ответа на запрос, вернитесь в личный кабинет и ожидайте обновления статуса подключения.</p>
  </div>
)
