import React from 'react'
import MediaQuery from 'react-responsive'
import mediaSize from '../../../constants/mediaSize'

import styles from './styles.module.scss'

const CardIssue = () => (
  <div className={styles.card}>
    <h3 className={styles.cardTitle}>
      { 'Площадкам' }
    </h3>
    <p className={styles.cardText}>
      { 'Безопасная сделка между заказчиками и исполнителями значительно упростит работу '
        + 'с\u00A0физическими лицами. Мы возьмем на себя все расчёты и\u00A0любую бюрократию.' }
    </p>
  </div>
)

const PayoutsCard = () => (
  <div className={styles.card}>
    <h3 className={styles.cardTitle}>
      { 'Продавцам' }
    </h3>
    <p className={styles.cardText}>
      { 'Своевременные расчёты, проверка размещаемой информации о товарах и услугах на соответствие '
      + 'текущему законодательству, защита интересов при работе с вашими клиентами.' }
    </p>
  </div>
)

const PaymentsCard = () => (
  <div className={styles.card}>
    <h3 className={styles.cardTitle}>
      { 'Покупателям' }
    </h3>
    <p className={styles.cardText}>
      { 'Мы интегрированы с большим количеством банков и\u00A0платежных систем. '
      + 'Это позволяет без ограничений заплатить за товар или услугу продавца.' }
    </p>
    <div className={styles.cardImagesWrap}>
      <i className="i_visa" />
      <MediaQuery minWidth={mediaSize.maxPhoneSize}>
        <i className="i_mir" />
      </MediaQuery>
      <i className="i_mastercard" />
      <i className="i_qiwi" />
      <i className="i_yoomoney" />
      <i className="i_plus" />
    </div>
  </div>
)

const AllInOneCard = () => (
  <div className={styles.card}>
    <h3 className={styles.cardTitle}>
      { 'Один договор' }
    </h3>
    <p className={styles.cardText}>
      { 'Вам не придётся заключать договор для каждой услуги по\u00A0отдельности. Единая точка входа '
      + 'с юридической и\u00A0технической стороны поможет сократить время подключения.' }
    </p>
  </div>
)

const SelfEmployedCard = () => (
  <div className={styles.card}>
    <h3 className={styles.cardTitle}>
      { 'Самозанятым' }
    </h3>
    <p className={styles.cardText}>
      { 'Наше API поможет вам в\u00A0работе с\u00A0самозанятыми, ведь наш сервис включён в '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://npd.nalog.ru/aggregators/"
        className={styles.cardLink}
      >
        {'перечень'}
      </a>
      {' операторов, осуществляющих информационный обмен с ФНС России в\u00A0режиме онлайн.' }
    </p>
  </div>
)

export default () => (
  <div className={styles.wrap}>
    <MediaQuery minWidth={mediaSize.maxPhoneSize}>
      <i className={styles.waveTop} />
    </MediaQuery>
    <div className="base-layout">
      <div className={styles.content}>
        <div className={styles.column}>
          <MediaQuery minWidth={mediaSize.maxPhoneSize}>
            <img
              alt="arrows"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/business/advantages/arrows.svg`}
              className={styles.arrows}
            />
          </MediaQuery>
          <AllInOneCard />
          <div className={styles.illustrationWrap}>
            <img
              alt="illustrationBack"
              /* eslint-disable-next-line max-len */
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/business/advantages/illustrationBack.webp`}
              width={420}
              height={364}
              className={styles.illustrationBack}
            />
            <img
              alt="illustrationCube"
              /* eslint-disable-next-line max-len */
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/business/advantages/cube.png`}
              width={225}
              height={160}
              className={styles.illustrationCube}
            />
            <img
              alt="cubeTop"
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/business/advantages/cubeTop.png`}
              width={60}
              height={54}
              className={styles.cubeTop}
            />
            <img
              alt="cubeBottom"
              /* eslint-disable-next-line max-len */
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/business/advantages/cubeBottom.png`}
              width={62}
              height={55}
              className={styles.cubeBottom}
            />
            <img
              alt="cubeLeft"
              /* eslint-disable-next-line max-len */
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/business/advantages/cubeLeft.png`}
              width={66}
              height={61}
              className={styles.cubeLeft}
            />
            <img
              alt="illustrationFront"
              /* eslint-disable-next-line max-len */
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/business/advantages/illustrationFront.svg`}
              width={440}
              height={260}
              className={styles.illustrationFront}
            />
            <img
              alt="cubeRight"
              /* eslint-disable-next-line max-len */
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/business/advantages/cubeRight.png`}
              width={81}
              height={72}
              className={styles.cubeRight}
            />
          </div>
          <MediaQuery minWidth={mediaSize.maxPhoneSize}>
            <PayoutsCard />
          </MediaQuery>
        </div>
        <div className={styles.column}>
          <PaymentsCard />
          <MediaQuery maxWidth={mediaSize.maxPhoneSize - 1}>
            <PayoutsCard />
          </MediaQuery>
          <SelfEmployedCard />
          <CardIssue />
          <MediaQuery minWidth={mediaSize.maxPhoneSize}>
            <img
              alt="blobBig"
              /* eslint-disable-next-line max-len */
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/business/advantages/blob_big.png`}
              className={styles.blobBig}
            />
            <img
              alt="blobSmall"
              /* eslint-disable-next-line max-len */
              src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/business/advantages/blob_small.png`}
              className={styles.blobSmall}
            />
          </MediaQuery>
        </div>
      </div>
    </div>
  </div>
)
