import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import styles from './styles.module.scss'

import arrowRightSmall from '../../img/arrow_small_right_black.svg'
import blob from './img/blob.png'
import hamburger from './img/hamburger.svg'
import cross from './img/cross.svg'
import cup from './img/cup.svg'

class MobileMenu extends React.PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isShow: false,
    }

    this.invite = localStorage.getItem('invite')
  }

  handleOpenMenu = () => {
    document.body.style.overflow = 'hidden'
    this.setState({ isShow: true })
  }

  handleCloseMenu = () => {
    document.body.style.overflow = 'auto'
    this.setState({ isShow: false })
  }

  render() {
    const { pathname } = this.props.location
    const {
      isShow,
    } = this.state
    const {
      invite,
      handleOpenMenu,
      handleCloseMenu,
    } = this
    return (
      <div className={classNames(styles.wrap, isShow && styles.wrapShow)}>
        <button
          onClick={handleCloseMenu}
          className={styles.closeCup}
        />
        <img alt="blob" src={blob} className={styles.contentBlob} />
        <button
          type="button"
          onClick={handleOpenMenu}
          className={styles.hamburger}
        >
          <img alt="hamburger" src={hamburger} />
        </button>
        <div className={classNames(styles.contentWrap, isShow && styles.contentWrapShow)}>
          <img alt="cup" src={cup} className={styles.cupImage} />
          <div
            className={styles.content}
          >
            <div className={styles.linksWrap}>
              <NavLink
                to={pathname === '/business' ? '/' : '/business'}
                onClick={handleCloseMenu}
                className={classNames(
                  styles.link,
                  styles.linkBusiness,
                  pathname === '/business' && styles.linkBusinessActive
                )}
              >
                { 'Бизнес' }
              </NavLink>
            </div>
            <div className={styles.linksWrap}>
              <NavLink
                to="/tariffs"
                onClick={handleCloseMenu}
                className={styles.link}
              >
                { 'Тарифы' }
              </NavLink>
              <a
                href="https://docs.tome.ge/"
                className={styles.link}
              >
                { 'API' }
              </a>
              <NavLink
                to="/contacts"
                onClick={handleCloseMenu}
                className={styles.link}
              >
                { 'Контакты' }
              </NavLink>
            </div>
            <div className={styles.linksWrap}>
              <div className={styles.linksBottom}>
                <a
                  href={`https://lk.tome.ge/signin${invite ? `?invite=${invite}` : ''}`}
                  onClick={() => localStorage.removeItem('invite')}
                  target="_parent"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  { 'Войти' }
                  <img alt="signIn" src={arrowRightSmall} className={styles.linkSignInImage} />
                </a>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={handleCloseMenu}
            className={styles.hamburger}
          >
            <img alt="cross" src={cross} />
          </button>
        </div>
      </div>
    )
  }
}

export default MobileMenu
