import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles.module.scss'

class ButtonTabs extends PureComponent {
  constructor(props) {
    super(props)
    this.tabsRefs = []
    props.tabs.map((tab, tabIndex) => (
      this.tabsRefs[tabIndex] = React.createRef()
    ))
    this.state = {
      activeTabAnimation: false,
      activeSegmentParams: {
        width: 0,
        left: 0,
      },
    }
  }

  componentDidMount() {
    this.getActiveSegmentParams(() => {
      // для работы анимации только после первого выставления размеров
      setTimeout(() => this.setState({ activeTabAnimation: true }), 0)
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentTab !== this.props.currentTab) {
      this.getActiveSegmentParams()
    }
  }

  getActiveSegmentParams = (callback = () => {}) => {
    const {
      tabs,
      currentTab,
    } = this.props
    const { tabsRefs } = this
    const currentSegment = tabs.find(tab => tab.name === currentTab)
    const currentSegmentIndex = tabs.indexOf(currentSegment)
    const currentSegmentRef = tabsRefs[currentSegmentIndex]
    const currentSegmentWidth = currentSegmentRef.current.clientWidth
    let currentSegmentLeft = 0

    if (currentSegmentIndex !== 0) {
      for (let i = 0; i < currentSegmentIndex; i += 1) {
        currentSegmentLeft += tabsRefs[i].current.clientWidth
      }
    }

    this.setState({
      activeSegmentParams: {
        left: currentSegmentLeft,
        width: currentSegmentWidth,
      },
    })
    callback()
  }

  render() {
    const {
      wrapClassName,
      tabs,
      currentTab,
      isDisable,
      handlePress,
    } = this.props
    const {
      activeTabAnimation,
      activeSegmentParams,
    } = this.state
    const { tabsRefs } = this
    return (
      <div className={classNames(styles.wrap, wrapClassName)}>
        <div
          className={classNames(
            styles.currentSegment,
            activeTabAnimation && styles.currentSegmentAnimate,
          )}
          style={{
            width: activeSegmentParams.width,
            left: activeSegmentParams.left,
          }}
        />
        {tabs.map((tab, tabIndex) => (
          <button
            key={tab.name}
            ref={tabsRefs[tabIndex]}
            type="button"
            className={classNames(
              styles.tab,
              currentTab === tab.name && styles.tabActive,
              isDisable && styles.tabDisable
            )}
            onClick={() => handlePress(tab)}
          >
            {tab.name}
          </button>
        ))}
      </div>
    )
  }
}

ButtonTabs.propTypes = {
  wrapClassName: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })).isRequired,
  currentTab: PropTypes.string.isRequired,
  isDisable: PropTypes.bool,
  handlePress: PropTypes.func,
}

export default ButtonTabs
